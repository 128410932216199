// import { useDispatch } from "react-redux/es/hooks/useDispatch";
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet";
import './ItinerariesDetails.scss';
import i18n from 'i18next';
import Slider from "react-slick";

import {resources_callapi} from'../../common/CallApi';

// image
// import day_item_img from '../../assets/day_item_img.png';

// components
import ContactUs from '../../components/ContactUsBtn/ContactUsBtn';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';
// import MapTab from '../../components/MapTab/MapTab';


function ItinerariesDetails() {
    const { t } = useTranslation();
    const {categories,pagename,subpagename} = useParams();
    const [pageContent, setPageContent] = React.useState({});
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
    const [activeTab, setTabChange] = React.useState("tab-daytoday");
    const [bestPriceActive, setBestPriceActive] = React.useState(false);
    
    let location = useLocation();
    const navigate = useNavigate();
    const curLang = i18n.resolvedLanguage;
    const curPath = location.pathname;

    
    
    var stay_choice_item_slider_settings = {
      dots: true,
      arrows:false,
      infinite: true,
      autoplay:false,
      autoplaySpeed:3000,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const scrollToRateSection = ()=>{
      document.getElementById("details-tab-part").scrollIntoView({behavior: "smooth"});
    }

    useEffect(() => {
      if (curPath.includes("/tc")) {
        i18n.changeLanguage("tc");
      } else {
        i18n.changeLanguage("en");
      }

      const get_page_content = async () => {
        if (subpagename !== "" && subpagename !== undefined) {
          const api_page_slug = categories+'_'+pagename;
          const api_page_content = await resources_callapi("itineraries_details", subpagename);
          if (api_page_content.length > 0 && api_page_content.find((item) => item.slug == subpagename)) {
            let render_content = api_page_content.find((item) => item.slug == subpagename).acf;
            let day_list_child_items = Object.keys(render_content.content_daytoday).filter(key => key.startsWith('child_item_')).map(key => ({...render_content.content_daytoday[key],child_name: key})).filter(item => item.item_title.en !== "");
            render_content['day_list_child_items'] = day_list_child_items;
            let stay_list_child_items = Object.keys(render_content.content_where_to_stay).filter(key => key.startsWith('child_item_')).map(key => ({...render_content.content_where_to_stay[key],child_name: key})).filter(item => item.item_title.en !== "");
            render_content['stay_list_child_items'] = stay_list_child_items;
            setPageContent(render_content);
            // console.log("🚀 ~ render_content= ~ day_list_child_items:", render_content)
            // console.log("🚀 ~ render_content= ~ day_list_child_items:", Object.keys(render_content.content_rate.to_be_add_section))
            // console.log("🚀 ~ render_content = ~ content_child_items:", Object.keys(day_list_child_items[0].item_fight_details).filter(key => key.startsWith('item_fight_details_item') && day_list_child_items[0].item_fight_details[key].item_fight_details_type.en !== "" ).map(key => ({...day_list_child_items[0].item_fight_details[key],child_name: key})) );
          } else {
            console.log("no page_content");
            // const api_page_content_item1 = await resources_callapi("itineraries", "", "per_page=1");
            // const [name,subname] = api_page_content_item1[0].slug.split('_');
            // navigate(`/${curLang}/itineraries/${name}/${subname}`);
          }
        } else {
          const api_page_content_item1 = await resources_callapi("itineraries", "", "per_page=1");
          const [name,subname] = api_page_content_item1[0].slug.split('_');
          navigate(`/${curLang}/itineraries/${name}/${subname}`);
        }
      }
      get_page_content();

      // const get_all_sub_pagelist = async () => {
      //   let pageList = {"experience":[],"destination":[]};
      //   const api_pageList = await resources_callapi("itineraries", "","","page_list");

      //   api_pageList.map((pageListItem)=>{
      //     const [name,subname] = pageListItem.slug.split('_');
      //     if(pageList[name]) {
      //       pageList[name].push({'url':name+'/'+subname,'name':pageListItem.acf.page_url_name});
      //     }
      //   })
      //   // setPageList(pageList);

      // }
      // get_all_sub_pagelist();

    }, [curPath]);


    return (

      (pageContent) ? (
        <div className="page-container">
          <Helmet>
              <title>{(pageContent.page_title)?pageContent.page_title[curLang]:'Wild Senses Holidays'}</title>
          </Helmet>
          <div className={`itineraries-details-page-container ${pagename}`}>

            <div className="banner-section" style={(pageContent.banner_img)?(isMobile)?{backgroundImage: `url(`+ pageContent.banner_img.mobile +`)`}:{backgroundImage: `url(`+ pageContent.banner_img.desktop +`)`}:{}}>
              <div className="banner-text-section">
                <div className="page-title">
                  <div className="page-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M11.4697 1.46991C11.7626 1.17702 12.2374 1.17702 12.5303 1.46991L22.5303 11.4699C22.8232 11.7628 22.8232 12.2377 22.5303 12.5306L12.5303 22.5306C12.2374 22.8235 11.7626 22.8235 11.4697 22.5306L1.46967 12.5306C1.17678 12.2377 1.17678 11.7628 1.46967 11.4699L11.4697 1.46991ZM3.06066 12.0002L12 20.9396L20.9393 12.0002L12 3.0609L3.06066 12.0002Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M1.25 12.0002C1.25 11.586 1.58579 11.2502 2 11.2502H22C22.4142 11.2502 22.75 11.586 22.75 12.0002C22.75 12.4145 22.4142 12.7502 22 12.7502H2C1.58579 12.7502 1.25 12.4145 1.25 12.0002Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M12.2155 1.28183C12.6123 1.40085 12.8374 1.81896 12.7184 2.21571L9.78302 12.0002L12.7184 21.7847C12.8374 22.1814 12.6123 22.5995 12.2155 22.7186C11.8188 22.8376 11.4007 22.6125 11.2816 22.2157L8.28163 12.2157C8.23946 12.0751 8.23946 11.9253 8.28163 11.7847L11.2816 1.78469C11.4007 1.38794 11.8188 1.1628 12.2155 1.28183Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M11.7847 1.28183C12.1814 1.1628 12.5995 1.38794 12.7186 1.78469L15.7186 11.7847C15.7607 11.9253 15.7607 12.0751 15.7186 12.2157L12.7186 22.2157C12.5995 22.6125 12.1814 22.8376 11.7847 22.7186C11.3879 22.5995 11.1628 22.1814 11.2818 21.7847L14.2172 12.0002L11.2818 2.21571C11.1628 1.81896 11.3879 1.40085 11.7847 1.28183Z" fill="white"/>
                    </svg>
                  </div>
                  <div className="page-name">{(pageContent.banner_page_title)?pageContent.banner_page_title[curLang]:''}</div>
                </div>
                <div className="sub-title">{(pageContent.banner_page_subtitle)?pageContent.banner_page_subtitle[curLang]:''}</div>
              </div>
            </div>
            
            <ContactUs></ContactUs>

            <div className="mb-best-price-btn" onClick={(e)=>{ if(isMobile){setBestPriceActive(true);}}}>
              <div className="name-section">
                <div className="from-text">From</div>
                    <div className="price-item">
                      <span className="price">{(pageContent.best_price)?pageContent.best_price.best_price_rate[curLang]:''}</span>
                      <span className="per">/ Adult</span>
                    </div>
              </div>
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M4.21967 15.5303C4.51256 15.8232 4.98744 15.8232 5.28033 15.5303L12 8.81066L18.7197 15.5303C19.0126 15.8232 19.4874 15.8232 19.7803 15.5303C20.0732 15.2374 20.0732 14.7626 19.7803 14.4697L12.5303 7.21967C12.2374 6.92678 11.7626 6.92678 11.4697 7.21967L4.21967 14.4697C3.92678 14.7626 3.92678 15.2374 4.21967 15.5303Z" fill="black"/>
                </svg>
              </div>
            </div>

            <div className="content-section">
              <div className={`content-details-section`}>
                <Link to={`/${curLang}/itineraries/${categories}/${pagename}`} className="back-btn">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M15.5303 4.21967C15.8232 4.51256 15.8232 4.98744 15.5303 5.28033L8.81066 12L15.5303 18.7197C15.8232 19.0126 15.8232 19.4874 15.5303 19.7803C15.2374 20.0732 14.7626 20.0732 14.4697 19.7803L7.21967 12.5303C6.92678 12.2374 6.92678 11.7626 7.21967 11.4697L14.4697 4.21967C14.7626 3.92678 15.2374 3.92678 15.5303 4.21967Z" fill="#BC9357"/>
                    </svg>
                  </div>
                  <div className="name">{t('itineraries_details_text.back')}</div>
                </Link>
                <div className="details-sub-section">
                  <div className="title">{(pageContent.content_page_title)?pageContent.content_page_title[curLang]:''}</div>
                  <div className="line-section"><div className="line"></div></div>
                </div>
                <div className="expect-section">
                  <div className="icon-title">
                    <div className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6C13.6045 6 14.5 5.10455 14.5 4C14.5 2.89543 13.6045 2 12.5 2C11.3954 2 10.5 2.89543 10.5 4C10.5 5.10455 11.3954 6 12.5 6Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 4C9.75 2.48121 10.9812 1.25 12.5 1.25C14.0188 1.25 15.25 2.48121 15.25 4C15.25 5.51876 14.0188 6.75 12.5 6.75C10.9812 6.75 9.75 5.51876 9.75 4ZM12.5 2.75C11.8097 2.75 11.25 3.30965 11.25 4C11.25 4.69034 11.8097 5.25 12.5 5.25C13.1903 5.25 13.75 4.69034 13.75 4C13.75 3.30965 13.1903 2.75 12.5 2.75Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 14C13.6045 14 14.5 13.1045 14.5 12C14.5 10.8954 13.6045 10 12.5 10C11.3954 10 10.5 10.8954 10.5 12C10.5 13.1045 11.3954 14 12.5 14Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 12C9.75 10.4812 10.9812 9.25 12.5 9.25C14.0188 9.25 15.25 10.4812 15.25 12C15.25 13.5188 14.0188 14.75 12.5 14.75C10.9812 14.75 9.75 13.5188 9.75 12ZM12.5 10.75C11.8097 10.75 11.25 11.3097 11.25 12C11.25 12.6903 11.8097 13.25 12.5 13.25C13.1903 13.25 13.75 12.6903 13.75 12C13.75 11.3097 13.1903 10.75 12.5 10.75Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6963 9.00034C18.2486 9.95694 19.4717 10.2847 20.4283 9.73239C21.3849 9.18009 21.7127 7.95694 21.1604 7.00034C20.6081 6.04374 19.3849 5.71599 18.4283 6.26829C17.4717 6.82059 17.144 8.04374 17.6963 9.00034Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0533 5.6189C19.3686 4.85949 21.0505 5.31016 21.8099 6.62547C22.5693 7.94079 22.1186 9.62263 20.8033 10.382C19.488 11.1414 17.8062 10.6908 17.0468 9.37547C16.2873 8.06015 16.738 6.37831 18.0533 5.6189ZM20.5109 7.37547C20.1657 6.77758 19.4012 6.57275 18.8033 6.91794C18.2054 7.26313 18.0006 8.02759 18.3458 8.62547C18.691 9.22336 19.4554 9.42819 20.0533 9.083C20.6512 8.73781 20.856 7.97335 20.5109 7.37547Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6963 15.0003C17.144 15.9569 17.4717 17.1801 18.4283 17.7324C19.3849 18.2847 20.6081 17.9569 21.1604 17.0003C21.7127 16.0437 21.3849 14.8206 20.4283 14.2683C19.4717 13.716 18.2486 14.0437 17.6963 15.0003Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0468 14.6255C17.8062 13.3102 19.488 12.8595 20.8033 13.6189C22.1186 14.3783 22.5693 16.0601 21.8099 17.3755C21.0505 18.6908 19.3686 19.1414 18.0533 18.382C16.738 17.6226 16.2873 15.9408 17.0468 14.6255ZM20.0533 14.9179C19.4554 14.5727 18.691 14.7776 18.3458 15.3755C18.0006 15.9733 18.2054 16.7378 18.8033 17.083C19.4012 17.4282 20.1657 17.2234 20.5109 16.6255C20.856 16.0276 20.6512 15.2631 20.0533 14.9179Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 18C11.3954 18 10.5 18.8954 10.5 20C10.5 21.1045 11.3954 22 12.5 22C13.6045 22 14.5 21.1045 14.5 20C14.5 18.8954 13.6045 18 12.5 18Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 20C9.75 18.4812 10.9812 17.25 12.5 17.25C14.0188 17.25 15.25 18.4812 15.25 20C15.25 21.5188 14.0188 22.75 12.5 22.75C10.9812 22.75 9.75 21.5188 9.75 20ZM12.5 18.75C11.8097 18.75 11.25 19.3097 11.25 20C11.25 20.6903 11.8097 21.25 12.5 21.25C13.1903 21.25 13.75 20.6903 13.75 20C13.75 19.3097 13.1903 18.75 12.5 18.75Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.30392 15.0003C6.75162 14.0437 5.52847 13.716 4.57187 14.2683C3.61528 14.8206 3.28753 16.0437 3.83982 17.0003C4.3921 17.9569 5.61527 18.2847 6.57187 17.7324C7.52847 17.1801 7.85622 15.9569 7.30392 15.0003Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.19687 13.6189C5.5122 12.8595 7.19404 13.3102 7.95344 14.6255C8.71286 15.9408 8.26219 17.6226 6.94688 18.382C5.63156 19.1414 3.94969 18.6908 3.1903 17.3755C2.43091 16.0601 2.88157 14.3783 4.19686 13.6189L4.19687 13.6189ZM4.94687 14.9179C4.349 15.2631 4.14416 16.0276 4.48934 16.6255C4.83452 17.2233 5.59899 17.4282 6.19687 17.083C6.79476 16.7378 6.99959 15.9733 6.65441 15.3755C6.30921 14.7776 5.54476 14.5727 4.94688 14.9179" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.30392 9.00034C7.85622 8.04374 7.52847 6.82059 6.57187 6.26829C5.61527 5.71599 4.3921 6.04374 3.83982 7.00034C3.28753 7.95694 3.61528 9.18009 4.57187 9.73239C5.52847 10.2847 6.75162 9.95694 7.30392 9.00034Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.1903 6.62547C3.94969 5.31015 5.63156 4.85949 6.94688 5.6189C8.26219 6.37831 8.71286 8.06014 7.95344 9.37547C7.19404 10.6908 5.5122 11.1414 4.19687 10.382L4.19686 10.382C2.88157 9.62263 2.43091 7.94079 3.1903 6.62547ZM6.19687 6.91794C5.59899 6.57275 4.83452 6.77759 4.48934 7.37546C4.14416 7.97334 4.349 8.73781 4.94688 9.083L4.57187 9.73252L4.94688 9.083C5.54476 9.42819 6.30921 9.22335 6.65441 8.62547C6.99959 8.02759 6.79476 7.26313 6.19687 6.91794ZM4.57187 9.73252L4.57044 9.735L4.57187 9.73252Z" fill="#BC9357"/>
                      </svg>
                    </div>
                    <div className="title-name">{(pageContent.content_what_to_expect)?pageContent.content_what_to_expect.what_to_expect_title[curLang]:''}</div>
                  </div>
                  <div className="night-details">
                    <span className="item-details">{(pageContent.content_what_to_expect)?pageContent.content_what_to_expect.nights[curLang]:''}</span>
                    <span className="item-remark">{(pageContent.content_what_to_expect)?pageContent.content_what_to_expect.nights_remarks[curLang]:''}</span>
                  </div>
                  <div className="section-details">{(pageContent.content_what_to_expect)?pageContent.content_what_to_expect.desp[curLang]:''}</div>
                </div>
                <div className="highlight-section">
                  <div className="icon-title">
                    <div className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6C13.6045 6 14.5 5.10455 14.5 4C14.5 2.89543 13.6045 2 12.5 2C11.3954 2 10.5 2.89543 10.5 4C10.5 5.10455 11.3954 6 12.5 6Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 4C9.75 2.48121 10.9812 1.25 12.5 1.25C14.0188 1.25 15.25 2.48121 15.25 4C15.25 5.51876 14.0188 6.75 12.5 6.75C10.9812 6.75 9.75 5.51876 9.75 4ZM12.5 2.75C11.8097 2.75 11.25 3.30965 11.25 4C11.25 4.69034 11.8097 5.25 12.5 5.25C13.1903 5.25 13.75 4.69034 13.75 4C13.75 3.30965 13.1903 2.75 12.5 2.75Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 14C13.6045 14 14.5 13.1045 14.5 12C14.5 10.8954 13.6045 10 12.5 10C11.3954 10 10.5 10.8954 10.5 12C10.5 13.1045 11.3954 14 12.5 14Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 12C9.75 10.4812 10.9812 9.25 12.5 9.25C14.0188 9.25 15.25 10.4812 15.25 12C15.25 13.5188 14.0188 14.75 12.5 14.75C10.9812 14.75 9.75 13.5188 9.75 12ZM12.5 10.75C11.8097 10.75 11.25 11.3097 11.25 12C11.25 12.6903 11.8097 13.25 12.5 13.25C13.1903 13.25 13.75 12.6903 13.75 12C13.75 11.3097 13.1903 10.75 12.5 10.75Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6963 9.00034C18.2486 9.95694 19.4717 10.2847 20.4283 9.73239C21.3849 9.18009 21.7127 7.95694 21.1604 7.00034C20.6081 6.04374 19.3849 5.71599 18.4283 6.26829C17.4717 6.82059 17.144 8.04374 17.6963 9.00034Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0533 5.6189C19.3686 4.85949 21.0505 5.31016 21.8099 6.62547C22.5693 7.94079 22.1186 9.62263 20.8033 10.382C19.488 11.1414 17.8062 10.6908 17.0468 9.37547C16.2873 8.06015 16.738 6.37831 18.0533 5.6189ZM20.5109 7.37547C20.1657 6.77758 19.4012 6.57275 18.8033 6.91794C18.2054 7.26313 18.0006 8.02759 18.3458 8.62547C18.691 9.22336 19.4554 9.42819 20.0533 9.083C20.6512 8.73781 20.856 7.97335 20.5109 7.37547Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6963 15.0003C17.144 15.9569 17.4717 17.1801 18.4283 17.7324C19.3849 18.2847 20.6081 17.9569 21.1604 17.0003C21.7127 16.0437 21.3849 14.8206 20.4283 14.2683C19.4717 13.716 18.2486 14.0437 17.6963 15.0003Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0468 14.6255C17.8062 13.3102 19.488 12.8595 20.8033 13.6189C22.1186 14.3783 22.5693 16.0601 21.8099 17.3755C21.0505 18.6908 19.3686 19.1414 18.0533 18.382C16.738 17.6226 16.2873 15.9408 17.0468 14.6255ZM20.0533 14.9179C19.4554 14.5727 18.691 14.7776 18.3458 15.3755C18.0006 15.9733 18.2054 16.7378 18.8033 17.083C19.4012 17.4282 20.1657 17.2234 20.5109 16.6255C20.856 16.0276 20.6512 15.2631 20.0533 14.9179Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 18C11.3954 18 10.5 18.8954 10.5 20C10.5 21.1045 11.3954 22 12.5 22C13.6045 22 14.5 21.1045 14.5 20C14.5 18.8954 13.6045 18 12.5 18Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 20C9.75 18.4812 10.9812 17.25 12.5 17.25C14.0188 17.25 15.25 18.4812 15.25 20C15.25 21.5188 14.0188 22.75 12.5 22.75C10.9812 22.75 9.75 21.5188 9.75 20ZM12.5 18.75C11.8097 18.75 11.25 19.3097 11.25 20C11.25 20.6903 11.8097 21.25 12.5 21.25C13.1903 21.25 13.75 20.6903 13.75 20C13.75 19.3097 13.1903 18.75 12.5 18.75Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.30392 15.0003C6.75162 14.0437 5.52847 13.716 4.57187 14.2683C3.61528 14.8206 3.28753 16.0437 3.83982 17.0003C4.3921 17.9569 5.61527 18.2847 6.57187 17.7324C7.52847 17.1801 7.85622 15.9569 7.30392 15.0003Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.19687 13.6189C5.5122 12.8595 7.19404 13.3102 7.95344 14.6255C8.71286 15.9408 8.26219 17.6226 6.94688 18.382C5.63156 19.1414 3.94969 18.6908 3.1903 17.3755C2.43091 16.0601 2.88157 14.3783 4.19686 13.6189L4.19687 13.6189ZM4.94687 14.9179C4.349 15.2631 4.14416 16.0276 4.48934 16.6255C4.83452 17.2233 5.59899 17.4282 6.19687 17.083C6.79476 16.7378 6.99959 15.9733 6.65441 15.3755C6.30921 14.7776 5.54476 14.5727 4.94688 14.9179" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.30392 9.00034C7.85622 8.04374 7.52847 6.82059 6.57187 6.26829C5.61527 5.71599 4.3921 6.04374 3.83982 7.00034C3.28753 7.95694 3.61528 9.18009 4.57187 9.73239C5.52847 10.2847 6.75162 9.95694 7.30392 9.00034Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.1903 6.62547C3.94969 5.31015 5.63156 4.85949 6.94688 5.6189C8.26219 6.37831 8.71286 8.06014 7.95344 9.37547C7.19404 10.6908 5.5122 11.1414 4.19687 10.382L4.19686 10.382C2.88157 9.62263 2.43091 7.94079 3.1903 6.62547ZM6.19687 6.91794C5.59899 6.57275 4.83452 6.77759 4.48934 7.37546C4.14416 7.97334 4.349 8.73781 4.94688 9.083L4.57187 9.73252L4.94688 9.083C5.54476 9.42819 6.30921 9.22335 6.65441 8.62547C6.99959 8.02759 6.79476 7.26313 6.19687 6.91794ZM4.57187 9.73252L4.57044 9.735L4.57187 9.73252Z" fill="#BC9357"/>
                      </svg>
                    </div>
                    <div className="title-name">{(pageContent.content_highlight)?pageContent.content_highlight.highlight_title[curLang]:''}</div>
                  </div>
                  {(pageContent.content_highlight && pageContent.content_highlight.desp) ?
                    <div className="section-details" dangerouslySetInnerHTML={{__html: pageContent.content_highlight.desp[curLang]}}></div>
                  :""}
                </div>
                <div className="mb-img-section">
                    {(pageContent.best_price)?
                      Object.keys(pageContent.best_price.item_best_price_imglist).filter(key => key.startsWith('item_best_price_img')).map((key,rateidx)=>{
                        if (pageContent.best_price.item_best_price_imglist[key]) {
                          return <img className="img-item" src={pageContent.best_price.item_best_price_imglist[key]} alt="" />
                        }
                      })
                    :''}
                  </div>

              </div>
              <div className={`best-price-section ${(bestPriceActive)?'active':''}`}>
                <div className="close-section show-mb" onClick={()=>setBestPriceActive(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z" fill="black"/>
                  </svg>
                </div>
                <div className="best-price-container">
                  <div className="content-details">
                    <div className="title">{t('itineraries_details_text.best_price')}</div>
                    <div className="line-section"><div className="line"></div></div>
                    <div className="from-text">From</div>
                    <div className="price-item">
                      <span className="price">{(pageContent.best_price)?pageContent.best_price.best_price_rate[curLang]:''}</span>
                      <span className="per">/ Adult</span>
                    </div>
                    <div className="view-all-rate-btn" onClick={()=>{setTabChange("tab-rate"); if(isMobile) {document.getElementById("travalTabMb").scrollIntoView({behavior: 'smooth',align:'top'}); setBestPriceActive(false);} else {document.getElementById("travalTabSection").scrollIntoView({behavior: 'smooth',align:'top'});}}}>{t('itineraries_details_text.view_all_rates')}</div>
                  </div>
                  <div className="img-section">
                    {(pageContent.best_price)?
                      Object.keys(pageContent.best_price.item_best_price_imglist).filter(key => key.startsWith('item_best_price_img')).map((key,rateidx)=>{
                        if (pageContent.best_price.item_best_price_imglist[key]) {
                          return <img className="img-item" src={pageContent.best_price.item_best_price_imglist[key]} alt="" />
                        }
                      })
                    :''}
                  </div>

                </div>
              </div>
            </div>
            
            <div className="details-tab-section">
              
              <div className="tab-btn-section-mb">
                <select className="item-selection" name="travalTabMb" id="travalTabMb" value={activeTab} onChange={(event)=>{ setTabChange(event.target.value) }}>
                  <option id="tab-daytoday-m" value="tab-daytoday">{t('itineraries_details_text.day_to_day')}</option>
                  <option  id="tab-wheretostay-m" value="tab-wheretostay">{t('itineraries_details_text.where_to_stay')}</option>
                  <option  id="tab-rate-m" value="tab-rate">{t('itineraries_details_text.rate')}</option>
                </select>
              </div>

              <div className="tab-btn-section" id="travalTabSection">
                <div id="tab-daytoday" className={`btn-item ${(activeTab == "tab-daytoday")?'active':''}`} onClick={()=>setTabChange("tab-daytoday")}>{t('itineraries_details_text.day_to_day')}</div>
                <div id="tab-wheretostay" className={`btn-item ${(activeTab == "tab-wheretostay")?'active':''}`} onClick={()=>{setTabChange("tab-wheretostay")}}>{t('itineraries_details_text.where_to_stay')}</div>
                <div id="tab-rate" className={`btn-item ${(activeTab == "tab-rate")?'active':''}`} onClick={()=>setTabChange("tab-rate")}>{t('itineraries_details_text.rate')}</div>
              </div>

              <div id="tab-content-daytoday" className={`tab-content daytoday-content  ${(activeTab == "tab-daytoday")?'active':''}`}>
                <div className="day-item-container">
                  {(pageContent.day_list_child_items)?
                    pageContent.day_list_child_items.map((dayItem,idx) => {
                      return <div className="day-item" key={idx}>
                        <img src={dayItem.day_img} alt="" className="item-img" />
                        <div className="day-item-details">
                          <div className="item-title">{dayItem.item_title[curLang]}</div>
                          {(dayItem.item_desp[curLang]) ?
                            <div className="desp" dangerouslySetInnerHTML={{__html: dayItem.item_desp[curLang]}}></div>
                          :""}
                          <div className="fight-details">
                            {/* <div className="desp">Depart from Hong Kong - Buenos Aires today. Here is our suggested flight options:</div> */}
                            
                            {
                              Object.keys(dayItem.item_fight_details).filter(key => key.startsWith('item_fight_details_item') && dayItem.item_fight_details[key].item_fight_details_type.en !== "" ).map(key => ({...dayItem.item_fight_details[key],child_name: key})).map((fightItem, fightIdx) => {
                                return <div className="details-item" key={fightIdx}>
                                  <div className="item item-type">{fightItem.item_fight_details_type[curLang]}</div>
                                  <div className="item item-where">{fightItem.item_fight_details_where[curLang]}</div>
                                  <div className="item item-company">{fightItem.item_fight_details_company[curLang]}</div>
                                  <div className="item item-time">{fightItem.item_fight_details_time[curLang]}</div>
                                </div>
                              })
                            }
                          </div>
                          {(dayItem.item_meal[curLang] && dayItem.item_meal[curLang]!=="") ?
                            <div className="meal-details">
                              <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M18.25 3.25C18.6297 3.25 18.9435 3.53215 18.9932 3.89823L19 4V20C19 20.4142 18.6642 20.75 18.25 20.75C17.8703 20.75 17.5565 20.4678 17.5068 20.1018L17.5 20V15H15.25C14.8703 15 14.5565 14.7178 14.5068 14.3518L14.5 14.25V7C14.5 4.92893 16.1789 3.25 18.25 3.25ZM12.25 3.25C12.6297 3.25 12.9435 3.53215 12.9932 3.89823L13 4V8C13 9.95258 11.6009 11.5784 9.7506 11.9297L9.75 20C9.75 20.4142 9.41421 20.75 9 20.75C8.6203 20.75 8.30651 20.4678 8.25685 20.1018L8.25 20L8.25039 11.9299C6.46566 11.5915 5.10054 10.0675 5.00531 8.20795L5 8V4C5 3.58579 5.33579 3.25 5.75 3.25C6.1297 3.25 6.44349 3.53215 6.49315 3.89823L6.5 4V8C6.5 9.11957 7.23593 10.0672 8.25042 10.3857L8.25 4C8.25 3.58579 8.58579 3.25 9 3.25C9.3797 3.25 9.69349 3.53215 9.74315 3.89823L9.75 4L9.75057 10.3854C10.7082 10.0843 11.4174 9.22253 11.4933 8.18486L11.5 8V4C11.5 3.58579 11.8358 3.25 12.25 3.25ZM17.5 13.5V4.87803C16.6775 5.16874 16.0745 5.9241 16.0064 6.82871L16 7V13.5H17.5V4.87803V13.5Z" fill="#BC9357"/>
                                </svg>
                              </div>
                              <div className="details">{dayItem.item_meal[curLang]}</div>
                            </div>
                          :''}
                          {(dayItem.item_stay.item_stay_item1 && dayItem.item_stay.item_stay_item1.item_stay_item1_title[curLang]!=="") ? 
                            <div className="stay-details">
                              <div className="stay-item classic">
                                <div className="icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M6.75 4H17.25C18.7125 4 19.9084 5.1417 19.995 6.58248L20 6.75L20.0006 10.1037C21.0968 10.414 21.9147 11.3872 21.9937 12.5628L22 12.75V20.25C22 20.6642 21.6642 21 21.25 21C20.8703 21 20.5565 20.7178 20.5068 20.3518L20.5 20.25V18H3.5V20.25C3.5 20.6297 3.21785 20.9435 2.85177 20.9932L2.75 21C2.3703 21 2.05651 20.7178 2.00685 20.3518L2 20.25V12.75C2 11.4911 2.84596 10.4297 4.00044 10.1034L4 6.75C4 5.28747 5.1417 4.0916 6.58248 4.00502L6.75 4ZM19.25 11.5H4.75C4.10279 11.5 3.57047 11.9919 3.50645 12.6222L3.5 12.75V16.5H20.5V12.75C20.5 12.1028 20.0081 11.5705 19.3778 11.5065L19.25 11.5ZM17.25 5.5H6.75C6.10279 5.5 5.57047 5.99187 5.50645 6.62219L5.5 6.75V10H7C7 9.44772 7.44772 9 8 9H10C10.5128 9 10.9355 9.38604 10.9933 9.88338L11 10H13C13 9.44772 13.4477 9 14 9H16C16.5128 9 16.9355 9.38604 16.9933 9.88338L17 10H18.5V6.75C18.5 6.10279 18.0081 5.57047 17.3778 5.50645L17.25 5.5Z" fill="#BC9357"/>
                                  </svg>
                                </div>
                                <div className="type">{dayItem.item_stay.item_stay_item1.item_stay_item1_title[curLang]}</div>
                                <div className="details">{dayItem.item_stay.item_stay_item1.item_stay_item1_name[curLang]}</div>
                              </div>
                              {(dayItem.item_stay.item_stay_item2.item_stay_item2_title[curLang]!=="")?
                                [<div className='item-or'> OR </div>,
                                <div className="stay-item luxury">
                                  <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M6.75 4H17.25C18.7125 4 19.9084 5.1417 19.995 6.58248L20 6.75L20.0006 10.1037C21.0968 10.414 21.9147 11.3872 21.9937 12.5628L22 12.75V20.25C22 20.6642 21.6642 21 21.25 21C20.8703 21 20.5565 20.7178 20.5068 20.3518L20.5 20.25V18H3.5V20.25C3.5 20.6297 3.21785 20.9435 2.85177 20.9932L2.75 21C2.3703 21 2.05651 20.7178 2.00685 20.3518L2 20.25V12.75C2 11.4911 2.84596 10.4297 4.00044 10.1034L4 6.75C4 5.28747 5.1417 4.0916 6.58248 4.00502L6.75 4ZM19.25 11.5H4.75C4.10279 11.5 3.57047 11.9919 3.50645 12.6222L3.5 12.75V16.5H20.5V12.75C20.5 12.1028 20.0081 11.5705 19.3778 11.5065L19.25 11.5ZM17.25 5.5H6.75C6.10279 5.5 5.57047 5.99187 5.50645 6.62219L5.5 6.75V10H7C7 9.44772 7.44772 9 8 9H10C10.5128 9 10.9355 9.38604 10.9933 9.88338L11 10H13C13 9.44772 13.4477 9 14 9H16C16.5128 9 16.9355 9.38604 16.9933 9.88338L17 10H18.5V6.75C18.5 6.10279 18.0081 5.57047 17.3778 5.50645L17.25 5.5Z" fill="#BC9357"/>
                                    </svg>
                                  </div>
                                  <div className="type">{dayItem.item_stay.item_stay_item2.item_stay_item2_title[curLang]}</div>
                                  <div className="details">{dayItem.item_stay.item_stay_item2.item_stay_item2_name[curLang]}</div>
                                </div>]
                              :""}
                            </div>
                          :""}
                        </div>

                      </div>

                    })
                  :''}
                  
                </div>
              </div>
              <div id="tab-content-wheretostay" className={`tab-content wheretostay-content  ${(activeTab == "tab-wheretostay")?'active':''}`}>
                <div className="stay-item-container">
                  {(pageContent.stay_list_child_items)?
                    pageContent.stay_list_child_items.map((stayItem,idx) => {
                      return <div className="stay-item" key={idx}>
                        <div className="title">{stayItem.item_title[curLang]}</div>
                        <div className="stay-choice-section">
                          {
                            Object.keys(stayItem.item_stay_details).filter(key => key.startsWith('item_stay_details_item')).map((key, detailsItemidx) => {
                              const stayDetailsItem = stayItem.item_stay_details[key];
                              const stayImgList = Object.keys(stayDetailsItem.item_stay_details_imglist).filter(key => key.startsWith('item_stay_details_img'));

                              if(stayDetailsItem.item_stay_details_name.en) {
                                return <div className="stay-choice-item" key={detailsItemidx}>
                                    {
                                      (stayDetailsItem && stayDetailsItem.item_stay_details_imglist.item_stay_details_img2) ? 
                                        <Slider {...stay_choice_item_slider_settings}>
                                          {
                                            stayImgList.map((key, imgidx) => {
                                              if(stayDetailsItem.item_stay_details_imglist[key]) {
                                                return <div className="item-img-container" key={imgidx}>
                                                  <img className="item-image" src={stayDetailsItem.item_stay_details_imglist[key]} alt=""/>
                                                </div>
                                              }
                                            })
                                          }
                                        </Slider>
                                        :
                                        <div className="item-img-container">
                                          <img className="item-image" src={(stayDetailsItem)?stayDetailsItem.item_stay_details_imglist.item_stay_details_img1:''} alt=""/>
                                        </div>
                                    }
                                    
                                    <div className="item-content">
                                      <div className="place">
                                        <div className="icon">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M18.1568 16.8819L16.9698 18.0558C16.0949 18.9144 14.9598 20.0184 13.5639 21.3679C12.6917 22.2113 11.3079 22.2112 10.4358 21.3677L6.94466 17.9718C6.5059 17.541 6.13863 17.1777 5.84279 16.8819C2.44238 13.4815 2.44238 7.9683 5.84279 4.56789C9.2432 1.16748 14.7564 1.16748 18.1568 4.56789C21.5572 7.9683 21.5572 13.4815 18.1568 16.8819ZM14.5 10.9996C14.5 9.61875 13.3806 8.49937 11.9998 8.49937C10.619 8.49937 9.49958 9.61875 9.49958 10.9996C9.49958 12.3804 10.619 13.4998 11.9998 13.4998C13.3806 13.4998 14.5 12.3804 14.5 10.9996Z" fill="black"/>
                                          </svg>
                                        </div>
                                        <div className="name">{(stayDetailsItem.item_stay_details_place)? stayDetailsItem.item_stay_details_place[curLang]:''}</div>
                                      </div>
                                      <div className="name-and-night">
                                        <div className="name-item">
                                          <div className="name">{(stayDetailsItem.item_stay_details_name)? stayDetailsItem.item_stay_details_name[curLang]:''}</div>
                                          <div className="remark">{(stayDetailsItem.item_stay_details_name_remarks)? stayDetailsItem.item_stay_details_name_remarks[curLang]:''}</div>
                                        </div>
                                        <div className="night-item">{(stayDetailsItem.item_stay_details_night)? stayDetailsItem.item_stay_details_night[curLang]:''}</div>
                                      </div>
                                      <div className="desp">{(stayDetailsItem.item_stay_details_desp)? stayDetailsItem.item_stay_details_desp[curLang]:''}</div>
                                      {(stayDetailsItem.item_stay_details_rate && stayDetailsItem.item_stay_details_rate !== "")? 
                                        <div className="rate-section">
                                          <div className="name">Rating</div>
                                          {[...Array(5).keys()].map((rateItem,idx)=>{
                                            if (rateItem < stayDetailsItem.item_stay_details_rate) {
                                              return <div className="rate-start full">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                  <path d="M10.7878 3.10215C11.283 2.09877 12.7138 2.09876 13.209 3.10215L15.567 7.87987L20.8395 8.64601C21.9468 8.80691 22.3889 10.1677 21.5877 10.9487L17.7724 14.6676L18.6731 19.9189C18.8622 21.0217 17.7047 21.8627 16.7143 21.342L11.9984 18.8627L7.28252 21.342C6.29213 21.8627 5.13459 21.0217 5.32374 19.9189L6.2244 14.6676L2.40916 10.9487C1.60791 10.1677 2.05005 8.80691 3.15735 8.64601L8.42988 7.87987L10.7878 3.10215Z" fill="#524538"/>
                                                </svg>
                                              </div>
                                            } else {
                                              return <div className="rate-start">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                  <path d="M10.7878 3.10215C11.283 2.09877 12.7138 2.09876 13.209 3.10215L15.567 7.87987L20.8395 8.64601C21.9468 8.80691 22.3889 10.1677 21.5877 10.9487L17.7724 14.6676L18.6731 19.9189C18.8622 21.0217 17.7047 21.8627 16.7143 21.342L11.9984 18.8627L7.28252 21.342C6.29213 21.8627 5.13459 21.0217 5.32374 19.9189L6.2244 14.6676L2.40916 10.9487C1.60791 10.1677 2.05005 8.80691 3.15735 8.64601L8.42988 7.87987L10.7878 3.10215ZM11.9984 4.03854L9.74008 8.61443C9.54344 9.01288 9.16332 9.28904 8.72361 9.35294L3.67382 10.0867L7.32788 13.6486C7.64606 13.9587 7.79125 14.4055 7.71614 14.8435L6.85353 19.8729L11.3702 17.4983C11.7635 17.2915 12.2333 17.2915 12.6266 17.4983L17.1433 19.8729L16.2807 14.8435C16.2056 14.4055 16.3508 13.9587 16.6689 13.6486L20.323 10.0867L15.2732 9.35294C14.8335 9.28904 14.4534 9.01288 14.2568 8.61443L11.9984 4.03854Z" fill="#524538"/>
                                                </svg>
                                              </div>
                                            }
                                          })}
                                        </div>
                                      :""}
                                    </div>

                                </div>
                              }
                            })

                          }
                        </div>
                      </div>

                    })
                  
                  :""}
                </div>
              </div>
              <div id="tab-content-rate" className={`tab-content rate-content  ${(activeTab == "tab-rate")?'active':''}`}>
                <div className="title-table-section all-rate-section">
                  <div className="section-title">{(pageContent.content_rate)?pageContent.content_rate.all_rate_section.section_title[curLang]:''}</div>
                  <div className="rate-table-container">
                    <div className="rate-table">
                      <div className="table-item table-title">
                        <div className="category"><span className="name">{(pageContent.content_rate)?pageContent.content_rate.all_rate_section.allrate_table_title.item_category[curLang]:''}</span></div>
                        <div className="mid-season">
                          <span className="name">{(pageContent.content_rate)?pageContent.content_rate.all_rate_section.allrate_table_title.item_mid_season[curLang]:''}</span>
                          <span className="remark">{(pageContent.content_rate)?pageContent.content_rate.all_rate_section.allrate_table_title.item_mid_season_remark[curLang]:''}</span>
                        </div>
                        <div className="peak-season">
                          <span className="name">{(pageContent.content_rate)?pageContent.content_rate.all_rate_section.allrate_table_title.item_peak_season[curLang]:''}</span>
                          <span className="remark">{(pageContent.content_rate)?pageContent.content_rate.all_rate_section.allrate_table_title.item_peak_season_remark[curLang]:''}</span>
                        </div>
                        <div className="shoulder-season">
                          <span className="name">{(pageContent.content_rate)?pageContent.content_rate.all_rate_section.allrate_table_title.item_shoulder_season[curLang]:''}</span>
                          <span className="remark">{(pageContent.content_rate)?pageContent.content_rate.all_rate_section.allrate_table_title.item_shoulder_season_remark[curLang]:''}</span>
                        </div>
                      </div>
                      {(pageContent.content_rate)?
                        Object.keys(pageContent.content_rate.all_rate_section).filter(key => key.startsWith('allrate_child_item')).map((key,rateidx)=>{
                          const rateItem = pageContent.content_rate.all_rate_section[key];
                          if (rateItem.item_category.en !== "") {
                            return <div className="table-item table-content">
                              <div className="category">{rateItem.item_category[curLang]}</div>
                              <div className="mid-season">{rateItem.item_mid_season[curLang]}</div>
                              <div className="peak-season">{rateItem.item_peak_season[curLang]}</div>
                              <div className="shoulder-season">{rateItem.item_shoulder_season[curLang]}</div>
                            </div>
                          }
                        })

                      :''}
                    </div>
                  </div>
                  {(pageContent.content_rate) ?
                    <div className="section-remark" dangerouslySetInnerHTML={{__html: pageContent.content_rate.all_rate_section.item_reamarks[curLang]}}></div>
                  :""}
                </div>
                <div className="title-table-section to-be-add-section">
                  <div className="section-title">{(pageContent.content_rate && pageContent.content_rate.to_be_add_section)?pageContent.content_rate.to_be_add_section.section_title[curLang]:''}</div>
                  <div className="rate-table-container">
                    <div className="rate-table">
                      <div className="table-item table-title">
                        <div className="airline"><span className="name">{(pageContent.content_rate)?pageContent.content_rate.to_be_add_section.tobeadd_table_title.item_airline[curLang]:''}</span></div>
                        <div className="from-to"><span className="name">{(pageContent.content_rate)?pageContent.content_rate.to_be_add_section.tobeadd_table_title.item_fromto[curLang]:''}</span></div>
                        <div className="air-class"><span className="name">{(pageContent.content_rate)?pageContent.content_rate.to_be_add_section.tobeadd_table_title.item_airclass[curLang]:''}</span></div>
                        <div className="fare-pp"><span className="name">{(pageContent.content_rate)?pageContent.content_rate.to_be_add_section.tobeadd_table_title.item_fare_per_person[curLang]:''}</span></div>
                      </div>
                      {(pageContent.content_rate)?
                        Object.keys(pageContent.content_rate.to_be_add_section).filter(key => key.startsWith('tobeadd_child_item')).map((key,rateidx)=>{
                          const toBeAddItem = pageContent.content_rate.to_be_add_section[key];
                          if (toBeAddItem.item_airline.en !== "") {
                            return <div className="table-item table-content">
                              <div className="airline">{toBeAddItem.item_airline[curLang]}</div>
                              <div className="from-to">{toBeAddItem.item_fromto[curLang]}</div>
                              <div className="air-class">{toBeAddItem.item_airclass[curLang]}</div>
                              <div className="fare-pp">{toBeAddItem.item_fare_per_person[curLang]}</div>
                            </div>
                          }
                        })
                      :''}
                    </div>
                  </div>
                  {(pageContent.content_rate && pageContent.content_rate.to_be_add_section.item_reamarks[curLang]) ?
                    <div className="section-remark" dangerouslySetInnerHTML={{__html: pageContent.content_rate.to_be_add_section.item_reamarks[curLang]}}></div>
                  :""}
                </div>
                <div className="icon-desp-section included-section">
                  <div className="icon-title">
                    <div className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 6C13.1045 6 14 5.10455 14 4C14 2.89543 13.1045 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10455 10.8954 6 12 6Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 4C9.25 2.48121 10.4812 1.25 12 1.25C13.5188 1.25 14.75 2.48121 14.75 4C14.75 5.51876 13.5188 6.75 12 6.75C10.4812 6.75 9.25 5.51876 9.25 4ZM12 2.75C11.3097 2.75 10.75 3.30965 10.75 4C10.75 4.69034 11.3097 5.25 12 5.25C12.6903 5.25 13.25 4.69034 13.25 4C13.25 3.30965 12.6903 2.75 12 2.75Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 14C13.1045 14 14 13.1045 14 12C14 10.8954 13.1045 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1045 10.8954 14 12 14Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 12C9.25 10.4812 10.4812 9.25 12 9.25C13.5188 9.25 14.75 10.4812 14.75 12C14.75 13.5188 13.5188 14.75 12 14.75C10.4812 14.75 9.25 13.5188 9.25 12ZM12 10.75C11.3097 10.75 10.75 11.3097 10.75 12C10.75 12.6903 11.3097 13.25 12 13.25C12.6903 13.25 13.25 12.6903 13.25 12C13.25 11.3097 12.6903 10.75 12 10.75Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1965 9.00034C17.7488 9.95694 18.972 10.2847 19.9286 9.73239C20.8852 9.18009 21.2129 7.95694 20.6606 7.00034C20.1083 6.04374 18.8852 5.71599 17.9286 6.26829C16.972 6.82059 16.6442 8.04374 17.1965 9.00034Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5532 5.6189C18.8685 4.85949 20.5504 5.31016 21.3098 6.62547C22.0692 7.94079 21.6185 9.62263 20.3032 10.382C18.9879 11.1414 17.306 10.6908 16.5466 9.37547C15.7872 8.06015 16.2379 6.37831 17.5532 5.6189ZM20.0107 7.37547C19.6655 6.77758 18.9011 6.57275 18.3032 6.91794C17.7053 7.26313 17.5005 8.02759 17.8457 8.62547C18.1909 9.22336 18.9553 9.42819 19.5532 9.083C20.1511 8.73781 20.3559 7.97335 20.0107 7.37547Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1965 15.0003C16.6442 15.9569 16.972 17.1801 17.9286 17.7324C18.8852 18.2847 20.1083 17.9569 20.6606 17.0003C21.2129 16.0437 20.8852 14.8206 19.9286 14.2683C18.972 13.716 17.7488 14.0437 17.1965 15.0003Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5466 14.6255C17.306 13.3102 18.9879 12.8595 20.3032 13.6189C21.6185 14.3783 22.0692 16.0601 21.3098 17.3755C20.5504 18.6908 18.8685 19.1414 17.5532 18.382C16.2379 17.6226 15.7872 15.9408 16.5466 14.6255ZM19.5532 14.9179C18.9553 14.5727 18.1909 14.7776 17.8457 15.3755C17.5005 15.9733 17.7053 16.7378 18.3032 17.083C18.9011 17.4282 19.6655 17.2234 20.0107 16.6255C20.3559 16.0276 20.1511 15.2631 19.5532 14.9179Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 18C10.8954 18 10 18.8954 10 20C10 21.1045 10.8954 22 12 22C13.1045 22 14 21.1045 14 20C14 18.8954 13.1045 18 12 18Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 20C9.25 18.4812 10.4812 17.25 12 17.25C13.5188 17.25 14.75 18.4812 14.75 20C14.75 21.5188 13.5188 22.75 12 22.75C10.4812 22.75 9.25 21.5188 9.25 20ZM12 18.75C11.3097 18.75 10.75 19.3097 10.75 20C10.75 20.6903 11.3097 21.25 12 21.25C12.6903 21.25 13.25 20.6903 13.25 20C13.25 19.3097 12.6903 18.75 12 18.75Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.80417 15.0003C6.25187 14.0437 5.02872 13.716 4.07211 14.2683C3.11553 14.8206 2.78778 16.0437 3.34006 17.0003C3.89235 17.9569 5.11552 18.2847 6.07212 17.7324C7.02872 17.1801 7.35647 15.9569 6.80417 15.0003Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69675 13.6189C5.01208 12.8595 6.69392 13.3102 7.45332 14.6255C8.21273 15.9408 7.76207 17.6226 6.44676 18.382C5.13144 19.1414 3.44957 18.6908 2.69018 17.3755C1.93079 16.0601 2.38145 14.3783 3.69674 13.6189L3.69675 13.6189ZM4.44675 14.9179C3.84888 15.2631 3.64404 16.0276 3.98922 16.6255C4.3344 17.2233 5.09887 17.4282 5.69675 17.083C6.29464 16.7378 6.49947 15.9733 6.15429 15.3755C5.80909 14.7776 5.04464 14.5727 4.44676 14.9179" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.80417 9.00034C7.35647 8.04374 7.02872 6.82059 6.07212 6.26829C5.11552 5.71599 3.89235 6.04374 3.34006 7.00034C2.78778 7.95694 3.11553 9.18009 4.07211 9.73239C5.02872 10.2847 6.25187 9.95694 6.80417 9.00034Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.69018 6.62547C3.44957 5.31015 5.13144 4.85949 6.44676 5.6189C7.76207 6.37831 8.21273 8.06014 7.45332 9.37547C6.69392 10.6908 5.01208 11.1414 3.69675 10.382L3.69674 10.382C2.38145 9.62263 1.93079 7.94079 2.69018 6.62547ZM5.69675 6.91794C5.09887 6.57275 4.3344 6.77759 3.98922 7.37546C3.64404 7.97334 3.84888 8.73781 4.44676 9.083L4.07175 9.73252L4.44676 9.083C5.04464 9.42819 5.80909 9.22335 6.15429 8.62547C6.49947 8.02759 6.29464 7.26313 5.69675 6.91794ZM4.07175 9.73252L4.07031 9.735L4.07175 9.73252Z" fill="#BC9357"/>
                      </svg>
                    </div>
                    <div className="name">{(pageContent.content_rate)?pageContent.content_rate.what_included_section.section_title[curLang]:''}</div>
                  </div>
                  {(pageContent.content_rate && pageContent.content_rate.what_included_section.item_desp[curLang]) ?
                    <div className="desp" dangerouslySetInnerHTML={{__html: pageContent.content_rate.what_included_section.item_desp[curLang]}}></div>
                  :""}
                </div>
                <div className="icon-desp-section excluded-section">
                  <div className="icon-title">
                    <div className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 6C13.1045 6 14 5.10455 14 4C14 2.89543 13.1045 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10455 10.8954 6 12 6Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 4C9.25 2.48121 10.4812 1.25 12 1.25C13.5188 1.25 14.75 2.48121 14.75 4C14.75 5.51876 13.5188 6.75 12 6.75C10.4812 6.75 9.25 5.51876 9.25 4ZM12 2.75C11.3097 2.75 10.75 3.30965 10.75 4C10.75 4.69034 11.3097 5.25 12 5.25C12.6903 5.25 13.25 4.69034 13.25 4C13.25 3.30965 12.6903 2.75 12 2.75Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 14C13.1045 14 14 13.1045 14 12C14 10.8954 13.1045 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1045 10.8954 14 12 14Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 12C9.25 10.4812 10.4812 9.25 12 9.25C13.5188 9.25 14.75 10.4812 14.75 12C14.75 13.5188 13.5188 14.75 12 14.75C10.4812 14.75 9.25 13.5188 9.25 12ZM12 10.75C11.3097 10.75 10.75 11.3097 10.75 12C10.75 12.6903 11.3097 13.25 12 13.25C12.6903 13.25 13.25 12.6903 13.25 12C13.25 11.3097 12.6903 10.75 12 10.75Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1965 9.00034C17.7488 9.95694 18.972 10.2847 19.9286 9.73239C20.8852 9.18009 21.2129 7.95694 20.6606 7.00034C20.1083 6.04374 18.8852 5.71599 17.9286 6.26829C16.972 6.82059 16.6442 8.04374 17.1965 9.00034Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5532 5.6189C18.8685 4.85949 20.5504 5.31016 21.3098 6.62547C22.0692 7.94079 21.6185 9.62263 20.3032 10.382C18.9879 11.1414 17.306 10.6908 16.5466 9.37547C15.7872 8.06015 16.2379 6.37831 17.5532 5.6189ZM20.0107 7.37547C19.6655 6.77758 18.9011 6.57275 18.3032 6.91794C17.7053 7.26313 17.5005 8.02759 17.8457 8.62547C18.1909 9.22336 18.9553 9.42819 19.5532 9.083C20.1511 8.73781 20.3559 7.97335 20.0107 7.37547Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1965 15.0003C16.6442 15.9569 16.972 17.1801 17.9286 17.7324C18.8852 18.2847 20.1083 17.9569 20.6606 17.0003C21.2129 16.0437 20.8852 14.8206 19.9286 14.2683C18.972 13.716 17.7488 14.0437 17.1965 15.0003Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5466 14.6255C17.306 13.3102 18.9879 12.8595 20.3032 13.6189C21.6185 14.3783 22.0692 16.0601 21.3098 17.3755C20.5504 18.6908 18.8685 19.1414 17.5532 18.382C16.2379 17.6226 15.7872 15.9408 16.5466 14.6255ZM19.5532 14.9179C18.9553 14.5727 18.1909 14.7776 17.8457 15.3755C17.5005 15.9733 17.7053 16.7378 18.3032 17.083C18.9011 17.4282 19.6655 17.2234 20.0107 16.6255C20.3559 16.0276 20.1511 15.2631 19.5532 14.9179Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 18C10.8954 18 10 18.8954 10 20C10 21.1045 10.8954 22 12 22C13.1045 22 14 21.1045 14 20C14 18.8954 13.1045 18 12 18Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 20C9.25 18.4812 10.4812 17.25 12 17.25C13.5188 17.25 14.75 18.4812 14.75 20C14.75 21.5188 13.5188 22.75 12 22.75C10.4812 22.75 9.25 21.5188 9.25 20ZM12 18.75C11.3097 18.75 10.75 19.3097 10.75 20C10.75 20.6903 11.3097 21.25 12 21.25C12.6903 21.25 13.25 20.6903 13.25 20C13.25 19.3097 12.6903 18.75 12 18.75Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.80417 15.0003C6.25187 14.0437 5.02872 13.716 4.07211 14.2683C3.11553 14.8206 2.78778 16.0437 3.34006 17.0003C3.89235 17.9569 5.11552 18.2847 6.07212 17.7324C7.02872 17.1801 7.35647 15.9569 6.80417 15.0003Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69675 13.6189C5.01208 12.8595 6.69392 13.3102 7.45332 14.6255C8.21273 15.9408 7.76207 17.6226 6.44676 18.382C5.13144 19.1414 3.44957 18.6908 2.69018 17.3755C1.93079 16.0601 2.38145 14.3783 3.69674 13.6189L3.69675 13.6189ZM4.44675 14.9179C3.84888 15.2631 3.64404 16.0276 3.98922 16.6255C4.3344 17.2233 5.09887 17.4282 5.69675 17.083C6.29464 16.7378 6.49947 15.9733 6.15429 15.3755C5.80909 14.7776 5.04464 14.5727 4.44676 14.9179" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.80417 9.00034C7.35647 8.04374 7.02872 6.82059 6.07212 6.26829C5.11552 5.71599 3.89235 6.04374 3.34006 7.00034C2.78778 7.95694 3.11553 9.18009 4.07211 9.73239C5.02872 10.2847 6.25187 9.95694 6.80417 9.00034Z" fill="#BC9357"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.69018 6.62547C3.44957 5.31015 5.13144 4.85949 6.44676 5.6189C7.76207 6.37831 8.21273 8.06014 7.45332 9.37547C6.69392 10.6908 5.01208 11.1414 3.69675 10.382L3.69674 10.382C2.38145 9.62263 1.93079 7.94079 2.69018 6.62547ZM5.69675 6.91794C5.09887 6.57275 4.3344 6.77759 3.98922 7.37546C3.64404 7.97334 3.84888 8.73781 4.44676 9.083L4.07175 9.73252L4.44676 9.083C5.04464 9.42819 5.80909 9.22335 6.15429 8.62547C6.49947 8.02759 6.29464 7.26313 5.69675 6.91794ZM4.07175 9.73252L4.07031 9.735L4.07175 9.73252Z" fill="#BC9357"/>
                      </svg>
                    </div>
                    <div className="name">{(pageContent.content_rate)?pageContent.content_rate.what_excluded_section.section_title[curLang]:''}</div>
                  </div>
                  {(pageContent.content_rate && pageContent.content_rate.what_excluded_section.item_desp[curLang]) ?
                    <div className="desp" dangerouslySetInnerHTML={{__html: pageContent.content_rate.what_excluded_section.item_desp[curLang]}}></div>
                  :""}
                </div>
              </div>
            </div>

          </div>
          <ContactUsForm></ContactUsForm>
        </div>
      ) : <div></div>
    );
  }
  
  export default ItinerariesDetails;