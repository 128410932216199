// import { useDispatch } from "react-redux/es/hooks/useDispatch";
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet";
import './MigrationMap.scss';
import i18n from 'i18next';

// components
import MapTab from '../../components/MapTab/MapTab';
import ContactUs from '../../components/ContactUsBtn/ContactUsBtn';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';


function MigrationMap() {
    const { t } = useTranslation();
    let location = useLocation();
    const navigate = useNavigate();
    const curLang = i18n.resolvedLanguage;
    const curPath = location.pathname;


    useEffect(() => {
      if (curPath.includes("/tc")) {
        i18n.changeLanguage("tc");
      } else {
        i18n.changeLanguage("en");
      }
    }, [curPath]);


    return (
      <div className="page-container">
        <Helmet>
            <title>{(t('migration_map_page_title'))?t('migration_map_page_title'):'Wild Senses Holidays'}</title>
        </Helmet>
        
        <div className={`migration-map-page-container`}>
          <ContactUs></ContactUs>
          <div className="migration-map-section">
            <div className="section-details-container">
              <div className="section-title">{t('migration_map_section_title')}</div>
              <MapTab></MapTab>
            </div>
          </div>
        </div>

        <ContactUsForm></ContactUsForm>
      </div>
    );
  }
  
  export default MigrationMap;